import api from "@/api";
import { commitT } from "@/models/type";
import { getFile } from "@/utils";
import { Vue, Prop, Watch } from "vue-property-decorator";
import './index.scss'

export interface FormRule {
  articleId?: number
  articleImg?: string
  articleName?: string
  articleIntroduce?: string
}

/**
 * @description 艺术家信息
 * @author Jinx
 * @date 2021-08-30 16:34:28
 * @export
 * @class ArticleInfo
 * @extends {Vue}
 */
export default class ArticleInfo extends Vue {

  @Prop(Number) articleId!: number
  @Prop(String) articleImg!: string
  @Prop(String) articleIntroduce!: string
  @Prop(Boolean) isInput!: boolean


  @Watch('articleId')
  getArticleId(articleId: number): void {
    if(this.articleList.length > 0) {
      this.form.articleId = articleId
    } else {
      this.getData(() => this.form.articleId = this.articleId)
    }
  }
  @Watch('articleImg')
  getArticleImg(articleImg: string): void {
    this.form.articleImg = articleImg
  }
  @Watch('articleIntroduce')
  getArticleIntroduce(articleIntroduce: string): void {
    this.form.articleIntroduce = articleIntroduce
  }

  public form: FormRule = {
    articleId: undefined,
    articleImg: '',
    articleName: '',
    articleIntroduce: ''
  }

  private url = ''
  private srcList: Array<string> = []

  private articleImgBool = false

  private articleList: Array<any> = []

  private articleChange(val: number): void {
    this.form.articleIntroduce = this.articleList.find(item => item.id === val)?.introduce
    this.form.articleImg = this.articleList.find(item => item.id === val)?.imgUrl
    this.form.articleName = this.articleList.find(item => item.id === val)?.title
  }

  uploadImg(prop: string): void {
    if(this[`${prop}Bool`]) {
      return
    }
    getFile(files => {
      if(!files.length) {
        return
      }
      this[`${prop}Bool`] = true
      const formData = new FormData()
      formData.append('file', files[0])
      api.commonOssUpload(formData).then(res => {
        this[`${prop}Bool`] = false
        this.form[prop] = res.data
        this.$emit('changeImg', this.form)
      })
    }, 'TEACHER', 'images/*')
  }

  showImg(url: string | Array<string>, i?: string): void {
    this.url = i || url as string
    this.srcList = url instanceof Array ? url : [url] as Array<string>
    this.imgViewerShow()
  }

  imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }

  getData(cb?: commitT): void {
    api.activeArticleGetArticleListSelect().then(res => {
      this.articleList = res.data
      cb && cb()
    })
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return (
      <el-row gutter={ 50 } class="article_info">
        <el-col lg={ 8 } sm= { 24 }>
          {
            this.isInput
              ? <el-form-item label="名称" prop="articleName" >
                <el-input v-model={ this.form.articleName } placeholder="请输入艺术家" style="width: 100%" clearable />
              </el-form-item>
              : <el-form-item label="名称" prop="articleId" >
                <el-select v-model={ this.form.articleId } placeholder="请选择艺术家" style="width: 100%" clearable onChange={ this.articleChange }>
                  {this.articleList.map(item => (
                    <el-option label={ item.title } value={ item.id } />
                  ))}
                </el-select>
              </el-form-item>
          }
          <el-form-item label="照片" prop="articleImg" >
            {this.articleImgBool
              ? <span class="el-form-tao-btn" >上传中...</span>
              : <span class="el-form-tao-btn" onClick={() => this.uploadImg('articleImg') }>{ this.form.articleImg ? '重新上传' : '上传' }</span>}
            {this.form.articleImg && <span class="el-form-tao-view" onClick={() => this.showImg(this.form.articleImg as string)} >预览</span>}
          </el-form-item>
        </el-col>
        <el-col lg={ 16 } sm= { 24 } class="article_info-cell">
          <el-form-item prop="articleIntroduce" label="个人介绍" >
            <el-input type="textarea" v-model={ this.form.articleIntroduce } clearable style="width: 100%" maxlength={ 60 } show-word-limit />
          </el-form-item>
        </el-col>
        {this.$slots.default?.()}
        {this.imgViewerDom()}
      </el-row>
    )
  }
}
